import Header from "../components/Header";

function Error() {
    return (
        <>
            <Header title="Error!" />
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <h1>You're not supposed to be here! :/</h1>
                </div>
            </main>
        </>
    );
}

export default Error;
