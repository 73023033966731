import { useState, useCallback, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { API } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import {
    TortQualifySurvey,
    InputTortQualifySurveyModify,
    InputQuestionModify,
    Option,
    OptionAction,
    Source,
    QuestionType,
    QuestionSubType,
} from "reliance-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { getTortQualifySurvey, getSources } from "reliance-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import TortQualifySurveyStore, {
    updateTortQualifySurveyStore,
    modifyTortQualifySurveyDetails,
    modifyQuestionDetails,
    addQuestion,
    removeQuestion,
    modifyOptionDetails,
    addOption,
    removeOption,
} from "../stores/TortQualifySurveyStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { notifyRealtime } from "../methods/notifyRealtime";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    addTortQualifySurvey,
    updateTortQualifySurvey,
} from "reliance-private-sitebuilder-schema/dist/graphql/mutations";

import { classNames, scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";
import { ToastMessage, ModificationError } from "../types/types";

const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function TortQualifySurveysModify() {
    type routeParams = {
        tqsurvey_id?: string;
    };

    const route_params: routeParams = useParams();

    const tqsurvey_store = useStore(TortQualifySurveyStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const doRefreshIds = () => {
        if (tqsurvey_store.holdingTortQualifySurvey?.id) {
            var refreshSurvey = _.cloneDeep(tqsurvey_store.holdingTortQualifySurvey);
            /// top survey ID
            refreshSurvey.id = uuidv4();
            /// each question
            refreshSurvey?.questions?.forEach((q) => {
                q!.id = uuidv4();
                q?.options?.forEach((o) => {
                    o!.id = uuidv4();
                });
            });
            updateTortQualifySurveyStore({
                holdingTortQualifySurvey: refreshSurvey,
            });
        }
    };

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};
            promises["sources"] = API.graphql({ query: getSources });

            var mode: string = route_params.tqsurvey_id ? "EDIT" : "ADD";
            updateTortQualifySurveyStore({
                mode: mode,
                section: "basics",
            });
            var graphQlValues: any;

            if (mode === "EDIT") {
                promises["tqsurvey"] = API.graphql({
                    query: getTortQualifySurvey,
                    variables: { id: route_params.tqsurvey_id },
                });
                try {
                    graphQlValues = await Promise.all([promises["sources"], promises["tqsurvey"]]);

                    // get pulled TortQualifySurvey
                    var rawTortQualifySurvey = graphQlValues[1].data.getTortQualifySurvey as TortQualifySurvey;

                    // convert to InputTortQualifySurveyModify
                    var inputTortQualifySurvey = _.cloneDeep(
                        rawTortQualifySurvey
                    ) as unknown as InputTortQualifySurveyModify;

                    updateTortQualifySurveyStore({
                        sources: graphQlValues[0].data.getSources as Source[],
                        holdingTortQualifySurvey: inputTortQualifySurvey,
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    graphQlValues = await Promise.all([promises["sources"]]);
                    updateTortQualifySurveyStore({
                        sources: graphQlValues[0].data.getSources as Source[],
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        loadData();
    }, [route_params]);

    const doRemoveQuestion = (questionIdx) => {
        if (tqsurvey_store.holdingTortQualifySurvey?.questions![questionIdx]?.id === tqsurvey_store.questionEditId) {
            updateTortQualifySurveyStore({ section: "basics" });
        }
        removeQuestion(questionIdx);
    };

    const handleTortQualifySurveyChange = (fieldName, fieldValue) => {
        modifyTortQualifySurveyDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleQuestionChange = (idx, fieldName, fieldValue) => {
        modifyQuestionDetails({ idx: idx, fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleQuestionOptionChange = (qIdx, idx, fieldName, fieldValue) => {
        modifyOptionDetails({ qIdx: qIdx, idx: idx, fieldName: fieldName, fieldValue: fieldValue });
    };

    const questionIdx = useMemo(() => {
        var qIdx = -1;
        if (tqsurvey_store.holdingTortQualifySurvey?.questions) {
            for (let q in tqsurvey_store?.holdingTortQualifySurvey.questions!) {
                if (tqsurvey_store?.holdingTortQualifySurvey.questions[q]!.id === tqsurvey_store.questionEditId) {
                    qIdx = Number(q);
                }
            }
        }
        return qIdx;
    }, [tqsurvey_store.holdingTortQualifySurvey?.questions, tqsurvey_store.questionEditId]);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId === "questionList" && result.destination.droppableId === "questionList") {
            const items = reorder(
                tqsurvey_store.holdingTortQualifySurvey!.questions!,
                result.source.index,
                result.destination.index
            );
            updateTortQualifySurveyStore({
                holdingTortQualifySurvey: {
                    ...tqsurvey_store.holdingTortQualifySurvey!,
                    questions: items as InputQuestionModify[],
                },
            });
        }
        if (result.source.droppableId === "optionList" && result.destination.droppableId === "optionList") {
            var itemSplit = result.draggableId.split("#");
            var qIdx = itemSplit[1];
            const itemsOptions = reorder(
                tqsurvey_store.holdingTortQualifySurvey!.questions![qIdx]!.options!,
                result.source.index,
                result.destination.index
            ) as Option[];
            var holdingTortQualifySurvey = _.cloneDeep(tqsurvey_store.holdingTortQualifySurvey!);
            holdingTortQualifySurvey!.questions![qIdx]!.options = itemsOptions;
            updateTortQualifySurveyStore({
                holdingTortQualifySurvey: {
                    ...holdingTortQualifySurvey!,
                },
            });
        }
    };

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();

        /// check for various things
        //////
        var errors: ModificationError[] = [];

        /// check tqsurvey name filled in
        if (!tqsurvey_store.holdingTortQualifySurvey?.name) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#name`,
                itemDescription: "Basic Section",
                message: `TortQualifySurvey Name missing`,
            });
        }
        /// check tqsurvey source filled
        if (!tqsurvey_store.holdingTortQualifySurvey?.source) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#source`,
                itemDescription: "Basic Section",
                message: `TortQualifySurvey Source Selection missing`,
            });
        }

        if (
            !tqsurvey_store.holdingTortQualifySurvey?.questions ||
            tqsurvey_store.holdingTortQualifySurvey?.questions.length === 0
        ) {
            errors.push({
                menuSection: ["questions"],
                itemDescription: "Questions",
                message: `At least one question needed`,
            });
        }

        /// check that each question is filled in properly
        tqsurvey_store.holdingTortQualifySurvey?.questions?.map((q, qIdx) => {
            if (!q?.description || q?.description === "") {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    item: `questions#${qIdx}#description`,
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `Question text missing`,
                });
            }
            if (!q?.token || q?.token === "") {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    item: `questions#${qIdx}#token`,
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `Question token missing`,
                });
            }
            if (!q?.options || q?.options.length < 2) {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `At least 2 options needed`,
                });
            }
            q?.options?.map((o, idx) => {
                if (!o?.value || o?.value === "") {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#value`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `option value missing`,
                    });
                }
                if (
                    o?.action &&
                    ["EDUCATE", "DISQUALIFY"].includes(o?.action) &&
                    (!o?.actionText || o?.actionText === "")
                ) {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#actionText`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `${o?.action} text missing missing`,
                    });
                }
                return true;
            });
            return true;
        });

        if (errors.length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateTortQualifySurveyStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (errors.length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            let postTqSurvey = _.cloneDeep(tqsurvey_store.holdingTortQualifySurvey!);
            delete postTqSurvey["__typename"];

            if (postTqSurvey?.questions) {
                postTqSurvey.questions = postTqSurvey?.questions?.map((e) => {
                    delete e!["__typename"];
                    if (e!.options) {
                        e!.options = e?.options.map((q) => {
                            delete q!["__typename"];
                            return q;
                        });
                    }
                    return e;
                });
            }

            promises["tqsurvey"] = API.graphql({
                query: tqsurvey_store.mode === "ADD" ? addTortQualifySurvey : updateTortQualifySurvey,
                variables: {
                    input: postTqSurvey,
                },
            });
            try {
                var add_result = await Promise.all([promises["tqsurvey"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("tqsurvey", tqsurvey_store.holdingTortQualifySurvey!.id!);
                if (tqsurvey_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/tqsurveys/${(add_result as any)[0].data.addTortQualifySurvey.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [tqsurvey_store.holdingTortQualifySurvey, tqsurvey_store.mode]);

    return (
        <>
            <Header
                title={`${tqsurvey_store.mode} Tort Qualify Survey`}
                toast={toastMsg}
                errors={tqsurvey_store.errors}
            />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", tqsurvey_store.errors)
                                                ? "bg-red-100"
                                                : tqsurvey_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateTortQualifySurveyStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 2444 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("questions", tqsurvey_store.errors)
                                                ? "bg-red-100"
                                                : tqsurvey_store.section === "questions"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateTortQualifySurveyStore({ section: "questions" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">
                                            Questions{" "}
                                            <span>
                                                [{tqsurvey_store?.holdingTortQualifySurvey?.questions?.length ?? "0"}]
                                            </span>
                                            <span
                                                className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                onClick={() => addQuestion()}
                                            >
                                                +
                                            </span>
                                        </span>
                                    </button>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="questionList">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {tqsurvey_store.holdingTortQualifySurvey?.questions?.map(
                                                        (q, idx) => (
                                                            <Draggable
                                                                key={idx}
                                                                draggableId={`Question#${idx}}`}
                                                                index={idx}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={idx}
                                                                        className={classNames("flex")}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="text-gray-500 group-hover:text-indigo-500 flex-shrink-0 ml-3 mr-1 mt-1 h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M4 6h16M4 10h16M4 14h16M4 18h16"
                                                                            />
                                                                        </svg>
                                                                        <button
                                                                            key={idx}
                                                                            className={classNames(
                                                                                "flex-grow mb-1 text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium text-left",
                                                                                menuSectionExistsInErrors(
                                                                                    `questions#${idx}`,
                                                                                    tqsurvey_store.errors
                                                                                )
                                                                                    ? "bg-red-100"
                                                                                    : tqsurvey_store.section ===
                                                                                      `question#${idx}`
                                                                                    ? "bg-gray-100"
                                                                                    : ""
                                                                            )}
                                                                            onClick={() => {
                                                                                updateTortQualifySurveyStore({
                                                                                    section: "editquestion",
                                                                                    questionEditId: q!.id!,
                                                                                });
                                                                            }}
                                                                            aria-current="page"
                                                                        >
                                                                            <span>
                                                                                {tqsurvey_store.holdingTortQualifySurvey!
                                                                                    .questions![idx]?.description &&
                                                                                tqsurvey_store.holdingTortQualifySurvey!
                                                                                    .questions![idx]?.description !== ""
                                                                                    ? tqsurvey_store.holdingTortQualifySurvey!
                                                                                          .questions![idx]?.description
                                                                                    : "New"}
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </nav>
                                {tqsurvey_store.holdingTortQualifySurvey?.id && (
                                    <div className="w-full text-center">
                                        <Link
                                            to={`/tqsurveys/new`}
                                            className="mt-8 mx-auto w-1/2 bg-green-600 border border-transparent rounded-xl shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                doRefreshIds();
                                            }}
                                        >
                                            (clone me)
                                        </Link>
                                    </div>
                                )}
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {tqsurvey_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic tqsurvey information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#name`, tqsurvey_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className={classNames("col-span-3 sm:col-span-2")}>
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        TortQualifySurvey Name
                                                    </label>
                                                    <div className={classNames("mt-1 rounded-md shadow-sm flex")}>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={tqsurvey_store.holdingTortQualifySurvey?.name ?? ""}
                                                            onChange={(e) =>
                                                                handleTortQualifySurveyChange(
                                                                    e.target.name!,
                                                                    e.target.value!
                                                                )
                                                            }
                                                            placeholder="ex: Roundup Quiz, Multi-Tort Quiz"
                                                            className={classNames(
                                                                "focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#source`, tqsurvey_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Source
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="source"
                                                            name="source"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={
                                                                tqsurvey_store.holdingTortQualifySurvey?.source ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                handleTortQualifySurveyChange(
                                                                    e.target.name!,
                                                                    e.target.value!
                                                                )
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {tqsurvey_store.sources.map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {tqsurvey_store.section === "questions" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    TortQualifySurvey Questions
                                                </h3>
                                            </div>

                                            <div className="grid grid-cols-3 gap-6">
                                                <button
                                                    type="button"
                                                    className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-1 rounded-full"
                                                    onClick={() => addQuestion()}
                                                >
                                                    Add Question
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {tqsurvey_store.section === "editquestion" && (
                                    <>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                        Question #{String(questionIdx + 1)}
                                                        <button
                                                            type="button"
                                                            className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                            onClick={() =>
                                                                addOption({
                                                                    qIdx: questionIdx,
                                                                })
                                                            }
                                                        >
                                                            Add Option
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="float-none ml-3 bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                            onClick={() =>
                                                                setModalInfo({
                                                                    ...modalInfo,
                                                                    open: true,
                                                                    title: "Remove Question",
                                                                    text: `Are you sure you wish to remove question #${
                                                                        questionIdx + 1
                                                                    }?`,
                                                                    action: () => {
                                                                        doRemoveQuestion(questionIdx);
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            Remove Question
                                                        </button>
                                                    </h3>
                                                </div>
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#description`,
                                                            tqsurvey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Text
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <input
                                                                type="text"
                                                                name="description"
                                                                id="description"
                                                                value={
                                                                    tqsurvey_store.holdingTortQualifySurvey?.questions![
                                                                        questionIdx
                                                                    ]?.description ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="ex: Have you ever used product..."
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#type`,
                                                            tqsurvey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Type
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <select
                                                                id="type"
                                                                name="type"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                value={
                                                                    tqsurvey_store.holdingTortQualifySurvey?.questions![
                                                                        questionIdx
                                                                    ]?.type ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {Object.keys(QuestionType).map((k, idx) => (
                                                                    <option key={k} value={k}>
                                                                        {k}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {tqsurvey_store.holdingTortQualifySurvey?.questions![questionIdx]
                                                    ?.type === "PICK_ONE" && (
                                                    <div
                                                        className={classNames(
                                                            "grid grid-cols-3 gap-6",
                                                            itemExistsInErrors(
                                                                `questions#${questionIdx}#subType`,
                                                                tqsurvey_store.errors
                                                            )
                                                                ? "bg-red-100"
                                                                : ""
                                                        )}
                                                    >
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                Question SubType
                                                            </label>
                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                <select
                                                                    id="subType"
                                                                    name="subType"
                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                    value={
                                                                        tqsurvey_store.holdingTortQualifySurvey
                                                                            ?.questions![questionIdx]?.subType ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleQuestionChange(
                                                                            questionIdx,
                                                                            e.target.name!,
                                                                            e.target.value!
                                                                        )
                                                                    }
                                                                >
                                                                    {Object.keys(QuestionSubType).map((k, idx) => (
                                                                        <option key={k} value={k}>
                                                                            {k}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tqsurvey_store.holdingTortQualifySurvey?.questions![questionIdx]
                                                    ?.type === "PICK_MULTI" && (
                                                    <div
                                                        className={classNames(
                                                            "grid grid-cols-3 gap-6",
                                                            itemExistsInErrors(
                                                                `questions#${questionIdx}#multiSelectPrompt`,
                                                                tqsurvey_store.errors
                                                            )
                                                                ? "bg-red-100"
                                                                : ""
                                                        )}
                                                    >
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                Multi Select Prompt (optional)
                                                            </label>
                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                <input
                                                                    type="text"
                                                                    name="multiSelectPrompt"
                                                                    id="multiSelectPrompt"
                                                                    value={
                                                                        tqsurvey_store.holdingTortQualifySurvey
                                                                            ?.questions![questionIdx]
                                                                            ?.multiSelectPrompt ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleQuestionChange(
                                                                            questionIdx,
                                                                            e.target.name!,
                                                                            e.target.value!
                                                                        )
                                                                    }
                                                                    placeholder="ex: Select all that apply"
                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#token`,
                                                            tqsurvey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Token
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <input
                                                                type="text"
                                                                name="token"
                                                                id="token"
                                                                value={
                                                                    tqsurvey_store.holdingTortQualifySurvey?.questions![
                                                                        questionIdx
                                                                    ]?.token ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="ex: Dx, Engage, Behalf"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3 gap-6">
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Bullets (optional - 1 per line)
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <textarea
                                                                rows={5}
                                                                name="bullets"
                                                                id="bullets"
                                                                value={
                                                                    tqsurvey_store.holdingTortQualifySurvey?.questions![
                                                                        questionIdx
                                                                    ]?.bullets ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="Bullet Options Here - 1 per line!"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="optionList">
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={`x${questionIdx}`}
                                                        className="mt-3"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {tqsurvey_store.holdingTortQualifySurvey?.questions![
                                                            questionIdx
                                                        ]?.options?.map((o, idx) => (
                                                            <Draggable
                                                                key={`d${idx}`}
                                                                draggableId={`Option#${questionIdx}#${idx}`}
                                                                index={idx}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={`o${idx}`}
                                                                        className="shadow mt-3 sm:rounded-md sm:overflow-hidden"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                                            <div>
                                                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                                                    Option #{idx + 1}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="float-none ml-3 bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                                                        onClick={() =>
                                                                                            setModalInfo({
                                                                                                ...modalInfo,
                                                                                                open: true,
                                                                                                title: "Remove Option",
                                                                                                text: `Are you sure you wish to remove option #${
                                                                                                    idx + 1
                                                                                                }?`,
                                                                                                action: () => {
                                                                                                    removeOption({
                                                                                                        qIdx: questionIdx,
                                                                                                        idx: idx,
                                                                                                    });
                                                                                                },
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        Remove Option
                                                                                    </button>
                                                                                </h3>
                                                                            </div>
                                                                            <div
                                                                                className={classNames(
                                                                                    "grid grid-cols-3 gap-6",
                                                                                    itemExistsInErrors(
                                                                                        `questions#${questionIdx}#options#${idx}#value`,
                                                                                        tqsurvey_store.errors
                                                                                    )
                                                                                        ? "bg-red-100"
                                                                                        : ""
                                                                                )}
                                                                            >
                                                                                <div className="col-span-3 sm:col-span-2">
                                                                                    <label
                                                                                        htmlFor="description"
                                                                                        className="block text-sm font-medium text-gray-700"
                                                                                    >
                                                                                        Text
                                                                                    </label>
                                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                                        <input
                                                                                            type="text"
                                                                                            name={`value#${o?.id}`}
                                                                                            id={`value#${o?.id}`}
                                                                                            value={o?.value ?? ""}
                                                                                            onChange={(e) =>
                                                                                                handleQuestionOptionChange(
                                                                                                    questionIdx,
                                                                                                    idx,
                                                                                                    e.target.name!,
                                                                                                    e.target.value!
                                                                                                )
                                                                                            }
                                                                                            placeholder="ex: Yes/No/Myself/Unsure"
                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {tqsurvey_store.holdingTortQualifySurvey
                                                                                ?.questions![questionIdx]?.type ===
                                                                                "PICK_MULTI" && (
                                                                                <>
                                                                                    <div
                                                                                        className={classNames(
                                                                                            "grid grid-cols-3 gap-6",
                                                                                            itemExistsInErrors(
                                                                                                `questions#${questionIdx}#options#${idx}#subValue`,
                                                                                                tqsurvey_store.errors
                                                                                            )
                                                                                                ? "bg-red-100"
                                                                                                : ""
                                                                                        )}
                                                                                    >
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor="description"
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                Sub Value (optional)
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name={`subValue#${o?.id}`}
                                                                                                    id={`subValue#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.subValue ??
                                                                                                        ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Such as taxes, fees, etc."
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={classNames(
                                                                                            "grid grid-cols-3 gap-6",
                                                                                            itemExistsInErrors(
                                                                                                `questions#${questionIdx}#options#${idx}#section`,
                                                                                                tqsurvey_store.errors
                                                                                            )
                                                                                                ? "bg-red-100"
                                                                                                : ""
                                                                                        )}
                                                                                    >
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor="description"
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                Attach Section Heading
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name={`section#${o?.id}`}
                                                                                                    id={`section#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.section ?? ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Expenses"
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <div
                                                                                className={classNames(
                                                                                    "grid grid-cols-3 gap-6",
                                                                                    itemExistsInErrors(
                                                                                        `questions#${questionIdx}#options#${idx}#action`,
                                                                                        tqsurvey_store.errors
                                                                                    )
                                                                                        ? "bg-red-100"
                                                                                        : ""
                                                                                )}
                                                                            >
                                                                                <div className="col-span-3 sm:col-span-2">
                                                                                    <label
                                                                                        htmlFor="description"
                                                                                        className="block text-sm font-medium text-gray-700"
                                                                                    >
                                                                                        Action
                                                                                    </label>
                                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                                        <select
                                                                                            id={`action#${o?.id}`}
                                                                                            name={`action#${o?.id}`}
                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                            value={o?.action ?? ""}
                                                                                            onChange={(e) =>
                                                                                                handleQuestionOptionChange(
                                                                                                    questionIdx,
                                                                                                    idx,
                                                                                                    e.target.name!,
                                                                                                    e.target.value!
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option value="">
                                                                                                (no action - proceed to
                                                                                                next step)
                                                                                            </option>
                                                                                            {Object.keys(OptionAction)
                                                                                                .filter((e, idx) => {
                                                                                                    return [
                                                                                                        "",
                                                                                                        "DISQUALIFY",
                                                                                                    ].includes(e);
                                                                                                })
                                                                                                .map((k, idx) => (
                                                                                                    <option
                                                                                                        key={idx}
                                                                                                        value={k}
                                                                                                    >
                                                                                                        {k}
                                                                                                    </option>
                                                                                                ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {o?.action &&
                                                                                ["EDUCATE", "DISQUALIFY"].includes(
                                                                                    o?.action
                                                                                ) && (
                                                                                    <div className="grid grid-cols-3 gap-6">
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor={`actionText#${o?.id}`}
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                {o?.action} Text
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <textarea
                                                                                                    rows={10}
                                                                                                    name={`actionText#${o?.id}`}
                                                                                                    id={`actionText#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.actionText ??
                                                                                                        ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Sorry, but this is required.."
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default TortQualifySurveysModify;
