import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";

import { useStore } from "effector-react";
import { Auth } from "aws-amplify";
import { useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { resetSurvey } from "../stores/SurveyStore";
import { resetTortQualifySurvey } from "../stores/TortQualifySurveyStore";
import { resetApp } from "../stores/AppStore";
import { resetContent } from "../stores/ContentStore";
import { resetSite } from "../stores/SiteStore";
import { resetFirm } from "../stores/FirmStore";

function NavFull() {
    const [showfullprofile, showfullprofileSet] = useState(false);
    const [showmobileprofile, showmobileprofileSet] = useState(false);
    const [showmobilemenu, showmobilemenuSet] = useState(false);

    const auth_user_store = useStore(AuthUserStore);

    const location = useLocation();
    const current = location.pathname;

    const currentSplit = current.split("/");

    const tabs: String[] = ["sites", "domains", "apps", "firms", "surveys", "tqsurveys", "contents", "routing"];

    return (
        <nav className={`${currentSplit[2] && currentSplit[2] === "realtime" ? "hidden" : ""} bg-gray-800`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <Link to="/">
                                <img
                                    className="h-8 w-8"
                                    src={"https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"}
                                    alt="Workflow"
                                />
                            </Link>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {tabs.map((p: any, index: number) => (
                                    <Link
                                        key={index}
                                        to={`/${p}`}
                                        href="/#"
                                        className={`${
                                            current === `/${p}`
                                                ? "bg-gray-900 text-white"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white"
                                        } px-3 py-2 rounded-md text-sm font-medium`}
                                        onClick={() => {
                                            if (p === "surveys") {
                                                resetSurvey();
                                            }
                                            if (p === "tqsurveys") {
                                                resetTortQualifySurvey();
                                            }
                                            if (p === "apps") {
                                                resetApp();
                                            }
                                            if (p === "contents") {
                                                resetContent();
                                            }
                                            if (p === "sites") {
                                                resetSite();
                                            }
                                            if (p === "firms") {
                                                resetFirm();
                                            }
                                        }}
                                    >
                                        {p.split(" ").map((s) => {
                                            return s === "tqsurveys"
                                                ? "TQ Surveys"
                                                : s.charAt(0).toUpperCase() + s.slice(1) + " ";
                                        })}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">View notifications</span>

                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                    />
                                </svg>
                            </button>

                            <div className="ml-3 relative">
                                <div>
                                    <button
                                        type="button"
                                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        id="user-menu"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                    >
                                        <img
                                            onClick={() => showfullprofileSet(!showfullprofile)}
                                            className="h-8 w-8 rounded-full"
                                            src={auth_user_store.gravatar}
                                            alt=""
                                        />
                                    </button>
                                </div>

                                <Transition
                                    show={showfullprofile}
                                    enter="transition ease-out duration-100 transform"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="transition ease-in duration-75 transform"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    {() => (
                                        <div
                                            className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="user-menu"
                                        >
                                            <Link
                                                to="/profile"
                                                href="/#"
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                role="menuitem"
                                            >
                                                Your Profile
                                            </Link>

                                            <Link
                                                to="/settings"
                                                href="/#"
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                role="menuitem"
                                            >
                                                Settings
                                            </Link>

                                            <button
                                                className="w-full text-white rounded bg-red-800 hover:bg-red-600"
                                                role="menuitem"
                                                onClick={() => Auth.signOut()}
                                            >
                                                Log Out
                                            </button>
                                        </div>
                                    )}
                                </Transition>
                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={() => showmobilemenuSet(!showmobilemenu)}
                            type="button"
                            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>

                            {/* closed */}
                            <svg
                                className={`${showmobilemenu ? "hidden" : "block"} h-6 w-6`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>

                            {/* open */}
                            <svg
                                className={`${showmobilemenu ? "block" : "hidden"} h-6 w-6`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className={showmobilemenu ? `md:hidden` : `hidden`} id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {tabs.map((p: any, index: number) => (
                        <Link
                            key={index}
                            to={`/${p}`}
                            href="/#"
                            className={`${
                                current === `/${p}`
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white"
                            } block px-3 py-2 rounded-md text-base font-medium`}
                            onClick={() => {
                                if (p === "surveys") {
                                    resetSurvey();
                                }
                                if (p === "tqsurveys") {
                                    resetTortQualifySurvey();
                                }
                                if (p === "apps") {
                                    resetApp();
                                }
                                if (p === "contents") {
                                    resetContent();
                                }
                                if (p === "sites") {
                                    resetSite();
                                }
                                if (p === "firms") {
                                    resetFirm();
                                }
                            }}
                        >
                            {p === "tqsurveys" ? "TQ Surveys" : p.charAt(0).toUpperCase() + p.slice(1) + " "}
                        </Link>
                    ))}
                    <button
                        className="w-full text-white rounded bg-red-800 hover:bg-red-600"
                        role="menuitem"
                        onClick={() => Auth.signOut()}
                    >
                        Log Out
                    </button>
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                id="user-menu"
                                aria-expanded="false"
                                aria-haspopup="true"
                            >
                                <img
                                    onClick={() => showmobileprofileSet(!showmobileprofile)}
                                    className="h-10 w-10 rounded-full"
                                    src={auth_user_store.gravatar}
                                    alt=""
                                />
                            </button>
                        </div>
                        <div className="ml-3">
                            <div className="text-base font-medium leading-none text-white">
                                {auth_user_store.isGoogle ? "Google Sign-In" : auth_user_store.username}
                            </div>
                            <div className="text-sm font-medium leading-none text-gray-400">
                                {auth_user_store.email}
                            </div>
                        </div>
                        <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">View notifications</span>

                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className={showmobileprofile ? `mt-3 px-2 space-y-1` : `hidden`}>
                        <Link
                            to="/profile"
                            href="/#"
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        >
                            Your Profile
                        </Link>

                        <Link
                            to="/settings"
                            href="/#"
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        >
                            Settings
                        </Link>

                        <button
                            className="w-full text-white rounded bg-red-800 hover:bg-red-600"
                            role="menuitem"
                            onClick={() => Auth.signOut()}
                        >
                            Log Out
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavFull;
