/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://3ya3pltltrd4fk37leerxahbma.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_ksqQ6U0b9",
    aws_user_pools_web_client_id: "503gi0ntcmogn3ha27t79109nv",
    aws_cognito_identity_pool_id: "us-west-2:e547eb3c-2f73-4c01-91c3-431f113736c0",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_ksqQ6U0b9",
        userPoolWebClientId: "503gi0ntcmogn3ha27t79109nv",
        identityPoolId: "us-west-2:e547eb3c-2f73-4c01-91c3-431f113736c0",
    },
    Storage: {
        AWSS3: {
            bucket: "reliance-sitebuilder-artifacts-stage",
            region: "us-west-2",
        },
    },
    mandatorySignIn: true,
    oauth: {
        domain: "stage.auth.reliancelitigation.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://stage.sitebuilder.reliancelitigation.com/",
        redirectSignOut: "https://stage.sitebuilder.reliancelitigation.com/",
        responseType: "code",
    },
};

export default awsmobile;
