import { Route, Switch } from "react-router-dom";

import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";
import { useStore } from "effector-react";

import Nav from "../components/Nav";

import Home from "./Home";

import Apps from "./Apps";
import AppsModify from "./AppsModify";

import Firms from "./Firms";
import FirmsModify from "./FirmsModify";

import Surveys from "./Surveys";
import SurveysModify from "./SurveysModify";

import TortQualifySurveys from "./TortQualifySurveys";
import TortQualifySurveysModify from "./TortQualifySurveysModify";

import Contents from "./Contents";
import ContentsModify from "./ContentsModify";

import Sites from "./Sites";
import SitesModify from "./SitesModify";
import SitesRealtime from "./SitesRealtime";

import Domains from "./Domains";
import DomainsNew from "./DomainsNew";

import Routing from "./Routing";

import Profile from "./Profile";
import Settings from "./Settings";

import Error from "./Error";

import Dashboard from "../components/Dashboard";
import Loader from "../components/Loader";

import Unauthorized from "./Unauthorized";

function Main() {
    const auth_user_store = useStore(AuthUserStore);
    return (
        <>
            {!auth_user_store.isParsed ? (
                <Loader />
            ) : (
                <>
                    {!auth_user_store.isLoggedIn && <Dashboard />}
                    {auth_user_store.isLoggedIn && !auth_user_store.isAdmin && <Unauthorized />}
                    {auth_user_store.isLoggedIn && auth_user_store.isAdmin && (
                        <>
                            <Nav />
                            <Switch>
                                <Route path="/" component={Home} exact />

                                <Route path="/sites" component={Sites} exact />
                                <Route path="/sites/new" component={SitesModify} exact />
                                <Route path="/sites/realtime/:site_id" component={SitesRealtime} />
                                <Route path="/sites/:site_id" component={SitesModify} />

                                <Route path="/apps" component={Apps} exact />
                                <Route path="/apps/new" component={AppsModify} exact />
                                <Route path="/apps/:app_id" component={AppsModify} />

                                <Route path="/firms" component={Firms} exact />
                                <Route path="/firms/new" component={FirmsModify} exact />
                                <Route path="/firms/:firm_id" component={FirmsModify} />

                                <Route path="/surveys" component={Surveys} exact />
                                <Route path="/surveys/new" component={SurveysModify} exact />
                                <Route path="/surveys/:survey_id" component={SurveysModify} />

                                <Route path="/tqsurveys" component={TortQualifySurveys} exact />
                                <Route path="/tqsurveys/new" component={TortQualifySurveysModify} exact />
                                <Route path="/tqsurveys/:tqsurvey_id" component={TortQualifySurveysModify} />

                                <Route path="/contents" component={Contents} exact />
                                <Route path="/contents/new" component={ContentsModify} exact />
                                <Route path="/contents/:content_id" component={ContentsModify} />

                                <Route path="/domains/new" component={DomainsNew} exact />
                                <Route path="/domains" component={Domains} exact />

                                <Route path="/routing" component={Routing} exact />

                                <Route path="/profile" component={Profile} exact />

                                <Route path="/settings" component={Settings} exact />

                                <Route component={Error} />
                            </Switch>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Main;
