import { ToastMessage } from "../types/types";
import { classNames } from "../utils/utils";

function Toast(props: { toast: ToastMessage }) {
    return (
        <>
            {props.toast.message && (
                <div
                    className={classNames(
                        props.toast.type === "ERROR" ? "bg-red-100" : "bg-green-500",
                        props.toast.type === "ERROR" ? "border-red-500" : "border-green-700 ",
                        "flex items-center  border-l-4 py-2 px-3 shadow-md mb-2"
                    )}
                    onClick={() => {
                        if (props.toast.callback) {
                            props.toast.callback();
                        }
                    }}
                >
                    <div
                        className={classNames(
                            props.toast.type === "ERROR" ? "text-red-500" : "text-green-500",
                            "rounded-full bg-white mr-3"
                        )}
                    >
                        <svg
                            width="1.8em"
                            height="1.8em"
                            viewBox="0 0 16 16"
                            className="bi bi-check"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                            />
                        </svg>
                    </div>

                    <div
                        className={classNames(props.toast.type === "ERROR" ? "text-red-500" : "text-white", "max-w-xs")}
                    >
                        {props.toast.message}
                    </div>
                </div>
            )}
        </>
    );
}

export default Toast;
