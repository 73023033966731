import { ModificationError } from "../types/types";
export const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export function menuSectionExistsInErrors(n: string, e: ModificationError[]) {
    for (let err of e) {
        if (err.menuSection?.includes(n)) {
            return true;
        }
    }
    return false;
}

export function itemExistsInErrors(n: string, e: ModificationError[]) {
    for (let err of e) {
        if (err.item === n || err.item?.includes(n)) {
            return true;
        }
    }
    return false;
}
