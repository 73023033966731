import { Link } from "react-router-dom";
import Toast from "./Toast";
import { ToastMessage, ModificationError } from "../types/types";

function Header({
    title,
    action_button,
    toast,
    errors,
}: {
    title: string;
    action_button?: { link?: string; text: string; callback?: () => void };
    toast?: ToastMessage;
    errors?: ModificationError[];
}) {
    return (
        <>
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="inline-block	text-3xl font-bold text-gray-900">{title}</h1>
                    {action_button && action_button.link && (
                        <Link to={action_button.link}>
                            <button className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                {action_button.text}
                            </button>
                        </Link>
                    )}
                    {action_button && action_button.callback && (
                        <button
                            onClick={() => action_button.callback!()}
                            className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        >
                            {action_button.text}
                        </button>
                    )}
                    {toast?.message && <Toast toast={toast} />}
                    <div className={"text-red-500"}>
                        {errors?.map((e, eIdx) => (
                            <div key={eIdx}>
                                {e.itemDescription}: {e.message}
                            </div>
                        ))}
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
