export const getSiteViewersByStatus = /* GraphQL */ `
    query GetSiteViewersByStatus($status: String!) {
        getSiteViewersByStatus(status: $status) {
            siteId
            site {
                app {
                    id
                }
                content {
                    id
                }
                survey {
                    id
                    questions {
                        options {
                            action
                            tortQualifySurveyIds
                        }
                    }
                }
                firm {
                    id
                }
            }
        }
    }
`;

export const getSitesDomainCheck = /* GraphQL */ `
    query GetSitesDomainCheck {
        getSites {
            id
            uri
            domain {
                id
            }
        }
    }
`;

export const getTortQualifySurveysShort = /* GraphQL */ `
    query GetTortQualifySurveysShort {
        getTortQualifySurveys {
            id
            name
            source
        }
    }
`;
