import { useLocation } from "react-router-dom";

import NavFull from "./NavFull";
import NavRealtime from "./NavRealtime";

function Nav() {
    const location = useLocation();
    const current = location.pathname;

    const currentSplit = current.split("/");

    return (currentSplit[2] && currentSplit[2] === "realtime" && <NavRealtime />) || <NavFull />;
}

export default Nav;
