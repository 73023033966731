import { createEvent, createStore } from "effector-logger";

import { ModificationError } from "../types/types";

import {
    App,
    Content,
    Survey,
    Domain,
    InputSiteModify,
    Firm,
    Source,
    PlatformIdentifier,
    InputPlatformIdentifierModify,
    UserGroup,
} from "reliance-private-sitebuilder-schema/dist/API";

export interface siteStore {
    holdingSite: Partial<InputSiteModify>;
    holdingPlatformIdentifiers: Record<string, InputPlatformIdentifierModify>;
    section: string;
    errors: ModificationError[];
    mode: string;
    apps: App[];
    firms: Firm[];
    userGroups: UserGroup[];
    sources: Source[];
    surveys: Survey[];
    contents: Content[];
    domains: Domain[];
    platformIdentifiers: Record<string, Record<string, PlatformIdentifier>>;
}

export const emptySiteStore: siteStore = {
    holdingSite: {
        uri: "/",
    },
    holdingPlatformIdentifiers: {},
    section: "basics",
    errors: [],
    apps: [],
    firms: [],
    userGroups: [],
    sources: [],
    surveys: [],
    contents: [],
    domains: [],
    mode: "",
    platformIdentifiers: {},
};

export type ModifySiteDetails = {
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export type ModifyHoldingPlatformIdentifierDetails = {
    fieldName: string;
    fieldValue: string;
};

export const updateSiteStore = createEvent<Partial<siteStore>>();

export const resetSite = createEvent();

export const modifySiteDetails = createEvent<ModifySiteDetails>();
export const modifyHoldingPlatformIdentifierDetails = createEvent<ModifyHoldingPlatformIdentifierDetails>();

export default createStore<siteStore>(emptySiteStore)
    .on(updateSiteStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetSite, (state) => {
        return {
            ...emptySiteStore,
        };
    })
    .on(modifyHoldingPlatformIdentifierDetails, (state, payload) => {
        var fieldNameSplit = payload.fieldName.split("#");
        state.holdingPlatformIdentifiers[fieldNameSplit[0]][fieldNameSplit[1]].value = payload.fieldValue;
        return {
            ...state,
        };
    })
    .on(modifySiteDetails, (state, payload) => {
        var update: any = {};
        if (typeof payload.fieldValue != "undefined") {
            if (
                ["showFirm", "allPlatforms", "surveySystemEnable", "backendTrackOnly", "deployActive"].includes(
                    payload.fieldName
                )
            ) {
                update[payload.fieldName] = payload.fieldValue === "yes" ? true : false;
            } else {
                update[payload.fieldName] = payload.fieldValue;
            }
        }
        if (typeof payload.fieldValues != "undefined") {
            update[payload.fieldName] = payload.fieldValues;
        }
        state.holdingSite = {
            ...state.holdingSite,
            ...update,
        };
        return {
            ...state,
        };
    });
