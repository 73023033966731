import { createEvent, createStore } from "effector-logger";
import { ModificationError } from "../types/types";
import {
    InputAppModify,
    ContentFieldType,
    InputContentFieldModify,
} from "reliance-private-sitebuilder-schema/dist/API";

import { v4 as uuidv4 } from "uuid";

export interface appStore {
    holdingApp?: Partial<InputAppModify>;
    section: string;
    contentTypeSection: ContentFieldType;
    holdingContentFields: Record<string, InputContentFieldModify>;
    errors: ModificationError[];
    mode: string;
}

export const emptyAppStore: appStore = {
    holdingApp: {
        name: "",
    },
    section: "basics",
    contentTypeSection: ContentFieldType.TEXT,
    holdingContentFields: {},
    errors: [],
    mode: "",
};

export type ModifyAppDetails = {
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export type ModifyContentDetails = {
    idx: number;
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export const resetApp = createEvent();
export const updateAppStore = createEvent<Partial<appStore>>();

export const addContent = createEvent();
export const removeContent = createEvent<string>();

export const modifyAppDetails = createEvent<ModifyAppDetails>();
export const modifyContentDetails = createEvent<ModifyContentDetails>();

export default createStore<appStore>(emptyAppStore)
    .on(updateAppStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetApp, (state) => {
        return {
            ...emptyAppStore,
        };
    })
    .on(modifyAppDetails, (state, payload) => {
        var update: any = {};
        if (typeof payload.fieldValue != "undefined") {
            if (["lockTort"].includes(payload.fieldName)) {
                update[payload.fieldName] = payload.fieldValue === "yes" ? true : false;
            } else {
                update[payload.fieldName] = payload.fieldValue;
            }
        }
        if (typeof payload.fieldValues != "undefined") {
            update[payload.fieldName] = payload.fieldValues;
        }
        state.holdingApp = {
            ...state.holdingApp,
            ...update,
        };
        return {
            ...state,
        };
    })
    .on(removeContent, (state, idx) => {
        delete state.holdingContentFields[idx];
        return {
            ...state,
        };
    })
    .on(modifyContentDetails, (state, payload) => {
        var fieldNameSplit = payload.fieldName.split("#");
        var fieldName = fieldNameSplit[0];
        state.holdingContentFields[payload.idx][fieldName] = payload.fieldValue;
        return {
            ...state,
        };
    })
    .on(addContent, (state) => {
        var id = uuidv4();
        state.holdingContentFields[id] = {
            id: id,
            type: state.contentTypeSection,
        };
        return {
            ...state,
        };
    });
