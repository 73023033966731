import { createEvent, createStore } from "effector-logger";

import { InputTortQualifySurveyModify, App, Source, OptionAction } from "reliance-private-sitebuilder-schema/dist/API";

import { v4 as uuidv4 } from "uuid";

import { ModificationError } from "../types/types";

// import * as _ from "lodash";

export interface tortQualifySurveyStore {
    holdingTortQualifySurvey?: Partial<InputTortQualifySurveyModify>;
    section: string;
    questionEditId: string;
    errors: ModificationError[];
    apps: App[];
    sources: Source[];
    mode: string;
}

export const emptyTortQualifySurveyStore: tortQualifySurveyStore = {
    holdingTortQualifySurvey: {
        name: "",
        questions: [],
    },
    section: "basics",
    questionEditId: "",
    errors: [],
    apps: [],
    sources: [],
    mode: "",
};

export type ModifyQuestionDetails = {
    idx: number;
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export type ModifyOptionDetails = {
    qIdx: number;
    idx: number;
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};
export type ModifyOptionMultiStringDetails = {
    qIdx: number;
    oIdx: number;
    iIdx: number;
    fieldName: string;
    fieldValue?: string;
};
export type AddOptionMultiStringDetails = {
    qIdx: number;
    oIdx: number;
};
export type RemoveOptionMultiStringDetails = {
    qIdx: number;
    oIdx: number;
    iIdx: number;
};

export type AddOptionDetails = {
    qIdx: number;
};
export type RemoveOptionDetails = {
    qIdx: number;
    idx: number;
};

export type ModifyTortQualifySurveyDetails = {
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export const updateTortQualifySurveyStore = createEvent<Partial<tortQualifySurveyStore>>();

export const modifyQuestionDetails = createEvent<ModifyQuestionDetails>();
export const addQuestion = createEvent();
export const removeQuestion = createEvent<number>();

export const modifyTortQualifySurveyDetails = createEvent<ModifyTortQualifySurveyDetails>();

export const modifyOptionDetails = createEvent<ModifyOptionDetails>();
export const addOption = createEvent<AddOptionDetails>();
export const removeOption = createEvent<RemoveOptionDetails>();

export const resetTortQualifySurvey = createEvent();

export default createStore<tortQualifySurveyStore>(emptyTortQualifySurveyStore)
    .on(updateTortQualifySurveyStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetTortQualifySurvey, (state) => {
        return {
            ...emptyTortQualifySurveyStore,
        };
    })
    .on(modifyTortQualifySurveyDetails, (state, payload) => {
        var update: any = {};
        if (payload.fieldValues) {
            update[payload.fieldName] = payload.fieldValues;
        } else {
            update[payload.fieldName] = payload.fieldValue;
        }
        state.holdingTortQualifySurvey = {
            ...state.holdingTortQualifySurvey,
            ...update,
        };
        return {
            ...state,
        };
    })
    .on(modifyQuestionDetails, (state, payload) => {
        var fieldNameSplit = payload.fieldName.split("#");
        var fieldName = fieldNameSplit[0];
        var update: any = {};
        update[fieldName] = payload.fieldValue;
        state.holdingTortQualifySurvey!.questions![payload.idx]! = {
            ...state.holdingTortQualifySurvey!.questions![payload.idx]!,
            ...update,
        };
        return {
            ...state,
        };
    })
    .on(modifyOptionDetails, (state, payload) => {
        var fieldNameSplit = payload.fieldName.split("#");
        var fieldName = fieldNameSplit[0];
        var update: any = {};
        update[fieldName] = payload.fieldValue;
        state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options![payload.idx]! = {
            ...state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options![payload.idx]!,
            ...update,
        };
        /// unset action if it is not a valid option (such as no action)
        if (payload.fieldName.startsWith("action#") && !Object.keys(OptionAction).includes(payload.fieldValue!)) {
            delete state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options![payload.idx]!.action;
        }
        return {
            ...state,
        };
    })
    .on(addOption, (state, payload) => {
        if (!state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options) {
            state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options = [];
        }
        state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options!.push({
            id: uuidv4(),
            value: "",
        });
        return {
            ...state,
        };
    })
    .on(removeOption, (state, payload) => {
        state.holdingTortQualifySurvey!.questions![payload.qIdx]!.options!.splice(payload.idx, 1);
        return {
            ...state,
        };
    })
    .on(addQuestion, (state) => {
        state.holdingTortQualifySurvey!.questions!.push({
            id: uuidv4(),
            description: "",
        });
        return {
            ...state,
        };
    })
    .on(removeQuestion, (state, idx) => {
        state.holdingTortQualifySurvey!.questions!.splice(idx, 1);
        return {
            ...state,
        };
    });
