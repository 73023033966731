import Header from "../components/Header";

function Settings() {
    return (
        <>
            <Header title="Settings" />
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <h1>Settings</h1>
                </div>
            </main>
        </>
    );
}

export default Settings;
