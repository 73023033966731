import React from "react";

function DashedContainer({ children }: { children: React.ReactNode | null }) {
    return (
        <div className="px-4 py-6 sm:px-0">
            <div className="border-4 border-dashed border-gray-200 rounded-lg">{children}</div>
        </div>
    );
}

export default DashedContainer;
