import { useState, useEffect } from "react";
import { classNames } from "../utils/utils";
export default function MediaPromise(props: { getter: () => Promise<string>; mediaType: string }) {
    const [url, urlSet] = useState<string>();
    useEffect(() => {
        props.getter().then((s) => urlSet(s));
    });
    return (
        <>
            {url && ["IMAGE", "FAVICON"].includes(props.mediaType) && (
                <button
                    onClick={async () => {
                        window.location.href = await props.getter();
                        return false;
                    }}
                >
                    <img alt="" src={url} className={classNames("max-h-32")} />
                </button>
            )}
            {url && props.mediaType === "VIDEO" && (
                <button
                    onClick={async () => {
                        window.location.href = await props.getter();
                        return false;
                    }}
                >
                    <video src={url} autoPlay={true} controls={true} loop={true} className={classNames("max-h-32")} />
                </button>
            )}
        </>
    );
}
