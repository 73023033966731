import Header from "../components/Header";
import DashedContainer from "../components/DashedContainer";
import Dashboard from "../components/Dashboard";

function Home() {
    return (
        <>
            <Header title="Dashboard" />
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <DashedContainer>
                        <Dashboard />
                    </DashedContainer>
                </div>
            </main>
        </>
    );
}

export default Home;
