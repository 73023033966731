import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

function Profile() {
    const auth_user = useStore(AuthUserStore);

    return (
        <>
            <Header title="Profile" />
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="mx-auto rounded rounded-t-lg overflow-hidden shadow max-w-xs my-3">
                        <img src={`/profilebg.png`} className="w-full" alt="" />
                        <div className="flex justify-center -mt-8">
                            <img
                                src={auth_user.gravatar}
                                className="rounded-full border-solid border-white border-2 -mt-3"
                                alt=""
                            />
                        </div>
                        <div className="text-center px-3 pb-6 pt-2">
                            <h3 className="text-black text-sm bold font-sans">{auth_user.username}</h3>
                            <p className="mt-2 font-sans font-light text-grey-dark">{auth_user.email}</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Profile;
