import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { API } from "aws-amplify";

import {
    App,
    InputAppModify,
    ContentFieldType,
    InputContentFieldModify,
} from "reliance-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { getApp } from "reliance-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import { SketchPicker } from "react-color";

import AppStore, {
    updateAppStore,
    modifyAppDetails,
    removeContent,
    modifyContentDetails,
    addContent,
} from "../stores/AppStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { addApp, updateApp } from "reliance-private-sitebuilder-schema/dist/graphql/mutations";

import { notifyRealtime } from "../methods/notifyRealtime";

import { classNames, scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";

import { ToastMessage, ModificationError } from "../types/types";

function AppsModify() {
    type routeParams = {
        app_id?: string;
    };

    const route_params: routeParams = useParams();

    const app_store = useStore(AppStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const parseContentFields = (x: InputContentFieldModify[]): Record<string, InputContentFieldModify> => {
        var result = {};

        for (let icf of x) {
            if (icf.id!) {
                result[icf!.id!] = icf;
            }
        }

        return result;
    };

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};

            var mode: string = route_params.app_id ? "EDIT" : "ADD";
            updateAppStore({
                mode: mode,
                section: "basics",
            });
            var graphQlValues: any;

            if (mode === "EDIT") {
                promises["app"] = API.graphql({ query: getApp, variables: { id: route_params.app_id } });
                try {
                    graphQlValues = await Promise.all([promises["app"]]);

                    // get pulled App
                    var rawApp = graphQlValues[0].data.getApp as App;

                    // convert to InputAppModify
                    var inputApp = _.cloneDeep(rawApp) as unknown as InputAppModify;

                    // take existing contentFields and modify to record type
                    var holdingContentFields = parseContentFields(
                        (inputApp.contentFields ?? []) as InputContentFieldModify[]
                    );

                    updateAppStore({
                        holdingApp: inputApp,
                        holdingContentFields: holdingContentFields,
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                loadingSet(false);
            }
        };
        loadData();
    }, [route_params]);

    const handleAppChange = (fieldName, fieldValue) => {
        modifyAppDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleContentChange = (idx, fieldName, fieldValue) => {
        modifyContentDetails({ idx: idx, fieldName: fieldName, fieldValue: fieldValue });
    };

    const getTypeCount = (t: string): string => {
        let c = Object.keys(app_store.holdingContentFields).filter(
            (q, idx) => app_store.holdingContentFields[q].type === t
        );
        if (c && (c ?? []).length > 0) {
            return ` (${c.length})`;
        }
        return "";
    };

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();

        /// check for various things
        //////
        var errors: ModificationError[] = [];

        /// check survey name filled in
        if (!app_store.holdingApp?.name) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#name`,
                itemDescription: "Basic Section",
                message: `App Name missing`,
            });
        }
        if (!app_store.holdingApp?.repository) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#repository`,
                itemDescription: "Basic Section",
                message: `Repository missing`,
            });
        }

        if (!app_store.holdingContentFields || Object.keys(app_store.holdingContentFields).length <= 0) {
            errors.push({
                menuSection: ["content"],
                itemDescription: "Content Section",
                message: `At least 1 content field needed`,
            });
        } else {
            Object.keys(app_store.holdingContentFields).map((id, cfIdx) => {
                if (!app_store.holdingContentFields[id]?.type) {
                    errors.push({
                        menuSection: ["content"],
                        item: `basics#name`,
                        itemDescription: "Basic Section",
                        message: `Survey Name missing`,
                    });
                }
                if (!app_store.holdingContentFields[id]?.name || app_store.holdingContentFields[id].name === "") {
                    errors.push({
                        menuSection: ["content", `content#${app_store.holdingContentFields[id]!.type!}`],
                        item: id,
                        itemDescription: `${app_store.holdingContentFields[id]!.type!} Content`,
                        message: `Content Name missing`,
                    });
                }
                return true;
            });
        }

        if (Object.keys(errors).length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateAppStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (Object.keys(errors).length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            /// unfurl the holdingContent to App
            var holdingAppContentFields: InputContentFieldModify[] = [];

            for (let cfid of Object.keys(app_store.holdingContentFields)) {
                let specHaCf = _.clone(app_store.holdingContentFields[cfid]);
                delete specHaCf["__typename"];
                holdingAppContentFields.push(specHaCf);
            }

            app_store.holdingApp!.contentFields = holdingAppContentFields;

            let postApp = _.cloneDeep(app_store.holdingApp!);
            delete postApp["__typename"];

            promises["app"] = API.graphql({
                query: app_store.mode === "ADD" ? addApp : updateApp,
                variables: {
                    input: postApp,
                },
            });
            try {
                var add_result = await Promise.all([promises["app"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("app", app_store.holdingApp!.id!);
                if (app_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/apps/${(add_result as any)[0].data.addApp.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [app_store.holdingApp, app_store.mode, app_store.holdingContentFields]);

    return (
        <>
            <Header title={`${app_store.mode} App`} toast={toastMsg} errors={app_store.errors} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", app_store.errors)
                                                ? "bg-red-100"
                                                : app_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateAppStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("content", app_store.errors)
                                                ? "bg-red-100"
                                                : app_store.section === "content"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateAppStore({ section: "content" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Required Content</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            app_store.errors["settings"]
                                                ? "bg-red-100"
                                                : app_store.section === "settings"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateAppStore({ section: "settings" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Settings</span>
                                    </button>
                                </nav>
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {app_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic app information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#name`, app_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        App Name
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={app_store.holdingApp?.name ?? ""}
                                                            onChange={(e) =>
                                                                handleAppChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: Standard Quiz App"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#repository`, app_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="repository"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Github Repository
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="repository"
                                                            id="repository"
                                                            value={app_store.holdingApp?.repository ?? ""}
                                                            onChange={(e) =>
                                                                handleAppChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: company/app.git"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {app_store.section === "content" && (
                                    <>
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                        Required app content
                                                        <button
                                                            type="button"
                                                            className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                            onClick={() => addContent()}
                                                        >
                                                            Add Content Field
                                                        </button>
                                                    </h3>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Define the content that is required for this app to display
                                                        properly
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="hidden sm:block">
                                                <nav className="flex space-x-4" aria-label="Tabs">
                                                    {Object.keys(ContentFieldType).map((tab) => (
                                                        <button
                                                            key={tab}
                                                            className={classNames(
                                                                tab === app_store.contentTypeSection
                                                                    ? "bg-gray-100 text-gray-700"
                                                                    : "text-gray-500 hover:text-gray-700",
                                                                "px-3 py-2 font-medium text-sm rounded-md",
                                                                menuSectionExistsInErrors(
                                                                    `content#${tab}`,
                                                                    app_store.errors
                                                                )
                                                                    ? "bg-red-100"
                                                                    : ""
                                                            )}
                                                            onClick={() => {
                                                                updateAppStore({
                                                                    contentTypeSection: tab as ContentFieldType,
                                                                });
                                                            }}
                                                            aria-current={
                                                                tab === app_store.contentTypeSection
                                                                    ? "page"
                                                                    : undefined
                                                            }
                                                        >
                                                            {tab}
                                                            {getTypeCount(tab)}
                                                        </button>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                        {Object.keys(app_store.holdingContentFields)
                                            ?.filter(
                                                (x, idx) =>
                                                    app_store.holdingContentFields[x].type ===
                                                    app_store.contentTypeSection
                                            )
                                            .map((q, contentIdx) => (
                                                <div
                                                    key={app_store.holdingContentFields[q].id}
                                                    className="shadow sm:rounded-md sm:overflow-hidden"
                                                >
                                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="float-none bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                                onClick={() =>
                                                                    setModalInfo({
                                                                        ...modalInfo,
                                                                        open: true,
                                                                        title: "Remove Question",
                                                                        text: `Are you sure you wish to remove content field #${
                                                                            contentIdx + 1
                                                                        }?`,
                                                                        action: () => {
                                                                            removeContent(q);
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                Remove Content
                                                            </button>
                                                        </div>
                                                        <div className={classNames("grid grid-cols-3 gap-6")}>
                                                            <div
                                                                className={classNames(
                                                                    "col-span-3 sm:col-span-2",
                                                                    itemExistsInErrors(q, app_store.errors)
                                                                        ? "bg-red-100"
                                                                        : ""
                                                                )}
                                                            >
                                                                <label
                                                                    htmlFor="description"
                                                                    className="block text-sm font-medium text-gray-700"
                                                                >
                                                                    Content Name
                                                                </label>
                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        id="name"
                                                                        value={
                                                                            app_store.holdingContentFields[q]?.name ??
                                                                            ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleContentChange(
                                                                                q,
                                                                                e.target.name!,
                                                                                e.target.value!
                                                                            )
                                                                        }
                                                                        placeholder="TOP_TITLE"
                                                                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {["CSS", "IMAGE", "TEXT", "TEXTAREA", "LIST"].includes(
                                                                app_store.holdingContentFields[q].type ?? ""
                                                            ) && (
                                                                <div className="col-span-3 sm:col-span-2">
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="block text-sm font-medium text-gray-700"
                                                                    >
                                                                        Default CSS
                                                                    </label>
                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                        <input
                                                                            type="text"
                                                                            name="css"
                                                                            id="css"
                                                                            value={
                                                                                app_store.holdingContentFields[q].css ??
                                                                                ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleContentChange(
                                                                                    q,
                                                                                    e.target.name!,
                                                                                    e.target.value!
                                                                                )
                                                                            }
                                                                            placeholder=""
                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {["TEXTAREA", "LIST"].includes(
                                                                app_store.holdingContentFields[q].type ?? ""
                                                            ) && (
                                                                <div className="col-span-3 sm:col-span-2">
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="block text-sm font-medium text-gray-700"
                                                                    >
                                                                        Default Value{" "}
                                                                        {app_store.holdingContentFields[q].type ===
                                                                        "LIST"
                                                                            ? "(one per line)"
                                                                            : ""}
                                                                    </label>
                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                        <textarea
                                                                            rows={5}
                                                                            name="value"
                                                                            id="value"
                                                                            value={
                                                                                app_store.holdingContentFields[q]
                                                                                    .value ?? ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleContentChange(
                                                                                    q,
                                                                                    e.target.name!,
                                                                                    e.target.value!
                                                                                )
                                                                            }
                                                                            placeholder=""
                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {["TEXT"].includes(
                                                                app_store.holdingContentFields[q].type ?? ""
                                                            ) && (
                                                                <div className="col-span-3 sm:col-span-2">
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="block text-sm font-medium text-gray-700"
                                                                    >
                                                                        Default Value
                                                                    </label>
                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                        <input
                                                                            type="text"
                                                                            name="value"
                                                                            id="value"
                                                                            value={
                                                                                app_store.holdingContentFields[q]
                                                                                    .value ?? ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleContentChange(
                                                                                    q,
                                                                                    e.target.name!,
                                                                                    e.target.value!
                                                                                )
                                                                            }
                                                                            placeholder=""
                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {["COLOR"].includes(
                                                                app_store.holdingContentFields[q].type ?? ""
                                                            ) && (
                                                                <div className="col-span-3 sm:col-span-2">
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="block text-sm font-medium text-gray-700"
                                                                    >
                                                                        Default Value
                                                                    </label>
                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                        <SketchPicker
                                                                            color={
                                                                                app_store.holdingContentFields[q]
                                                                                    .value &&
                                                                                app_store.holdingContentFields[q]
                                                                                    .value![0] === "{"
                                                                                    ? JSON.parse(
                                                                                          app_store
                                                                                              .holdingContentFields[q]
                                                                                              .value!
                                                                                      )
                                                                                    : { r: 1, g: 1, b: 1, a: 1 }
                                                                            }
                                                                            onChange={(color) => {
                                                                                console.log(color);
                                                                                handleContentChange(
                                                                                    q,
                                                                                    "value",
                                                                                    JSON.stringify(color.rgb)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </>
                                )}
                                {app_store.section === "settings" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic Settings
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Settings to help tailor the app requirements
                                                </p>
                                            </div>

                                            <div className="grid grid-cols-3 gap-6">
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Question Count
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <select
                                                            id="questionCount"
                                                            name="questionCount"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={String(app_store.holdingApp?.questionCount) ?? ""}
                                                            onChange={(e) =>
                                                                handleAppChange(e.target.name!, Number(e.target.value!))
                                                            }
                                                        >
                                                            <option value="">No Limit</option>
                                                            {Array.from(Array(10).keys())
                                                                .map((x) => x + 1)
                                                                .map((a, idx) => (
                                                                    <option key={idx} value={a}>
                                                                        {a}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-3 gap-6">
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Locked Tort Mode
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <label className="inline-flex items-center ml-4 mt-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox"
                                                                checked={app_store.holdingApp?.lockTort ?? false}
                                                                value={"yes"}
                                                                name="lockTort"
                                                                id="lockTort"
                                                                onChange={(e) =>
                                                                    handleAppChange(
                                                                        e.target.name!,
                                                                        app_store.holdingApp?.lockTort ? "no" : "yes"
                                                                    )
                                                                }
                                                            />
                                                            <span className="ml-1 text-sm font-medium text-gray-700">
                                                                Enabled
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default AppsModify;
